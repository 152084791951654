<template>
  <div>
    <div class="bg-w-silver">
      <div class="bg-orange">
        <div class="text-title --white text-center font-weight-bold py-3">
          {{
            change ?
              $t("reservationAndUsageHistory.changeGuestInformationChange") :
              $t("reservationAndUsageHistory.changeGuestInformation")
          }}
        </div>
      </div>
      <div class="px-4 mb-4">
        <div
          class="text-title --c-prm font-weight-bold px-1 pt-4"
          v-if="notChange">
          {{ $t('common.nGuest') }}
        </div>
        <div
          v-else
          class="text-medium text-center --c-bg text-left pt-3 pb-4"
        >
          {{ $t("reservationAndUsageHistory.pleaseEnterTheChangeInTheDesiredItem") }}
        </div>

        <div class="px-2">
          <!-- When booking changes START-->
          <v-container class="text-title" v-if="!notChange">
            <RoomGuestsNumber
              :newLineTitle="true"
              titleClass="--c-prm"
              labelClass="--c-medium-gray"
              :maxOccupancy="roomType.maxOccupancy"
              :bedShareMaxOccupancy="roomType.bedShareMaxOccupancy"
              :numberOfAdults="adultsCount"
              :numberOfChildren="childrenCount"
              :numberOfBedShare="bedShareCount"
              @onNumberOfAdultsChange="v => {adultsCount = v; onGuestNumberChange();}"
              @onNumberOfBedShareChange="v => {bedShareCount = v; onGuestNumberChange();}"
              @onNumberOfChildrenChange="v => {childrenCount = v; onGuestNumberChange();}"
            />
          </v-container>
          <!-- When booking changes END -->

          <!--When entering booking information START-->
          <div v-else class="--c-bg text-title">
            <v-container>
              <v-row>
                <v-col class="col-2 pa-0 my-2 vertical-space-between">
                  <div class="font-weight-bold">{{ $t("common.adult") }}</div>
                  <div class="mr-0 ml-auto">
                    <div class="ml-0 mr-auto pt-5">
                      <div>
                        <span class="text-max font-weight-regular">{{ adultsCount }}</span>
                        <span>{{ $t("common.firstName") }}</span>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-divider class="--border-light-gray my-4 mx-6" vertical></v-divider>
                <v-col class="text-center py-0 my-2">
                  <div class="font-weight-bold">{{ $t("common.children1") }}</div>
                  <div class="d-flex">
                    <div class="ml-0 mr-auto">
                      <span class="text-normal">{{ $t("common.coSleeping") }}</span>
                      <div>
                        <span class="text-max font-weight-regular">
                          {{ bedShareCount }}
                        </span>
                        <span>{{ $t("common.firstName") }}</span>
                      </div>
                    </div>
                    <div class="ml-0 mr-auto">
                      <span class="text-normal">{{ $t("common.noSleepTogether") }}</span>
                      <div>
                        <span class="text-max font-weight-regular">
                          {{ childrenCount }}
                        </span>
                        <span>{{ $t("common.firstName") }}</span>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <!--When entering booking information END -->
        </div>

        <div
          class="border-bold --border-prm px-7 my-3"
          v-if="facility && facility.facilityHpUrl"
        >
          <div class="text-normal text-center pt-5 pb-3 --prm">
            {{ $t("reservationAndUsageHistory.aboutCheckIn") }}
          </div>
          <div class="text-normal pb-4 --gg">
            {{ $t("text.orSpecificInfo") }}
          </div>
          <div class="text-normal pb-3 --bg">
            <a :href="facility.facilityHpUrl" target="_blank">
              {{ $t("text.facInfoPage") }}
              <IconOpen/>
            </a>
          </div>
        </div>

        <div>
          <div id="anchor" />
          <GuestInfoInput
            v-for="(guest, index) in value"
            :key="index"
            :preCheckIn="preCheckIn"
            :guest="guest"
            @change="newGuest => changeGuest(index, newGuest)"
            :representative="guest.representative"
            :index="getIndexOf(guest, index)"
            :isFirstBooking="isFirstBooking"
            :bookingTypeId="bookingTypeId"
          />
        </div>
        <div class="text-center pa-4 mt-4">
          <v-btn
            elevation="0"
            @click="togglePreCheckIn"
            rounded
            height="50"
            outlined
            class="bg-white --border-dark-smoke"
          >
            <span>{{ preCheckInLabel }}</span>
            <v-icon
              right
              class="--prm text-large"
            >
              {{ preCheckIn ? 'mdi-menu-up' : 'mdi-menu-down' }}
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GuestInfoInput from './GuestInfoInput.vue'
import IconOpen from '@/components/icons/IconOpen'
import { numberArrayGenerator } from '@/utils/generator'
import RoomGuestsNumber from '@/components/ReservationComponents/RoomGuestsNumber'

export default {
  components: {
    GuestInfoInput,
    IconOpen,
    RoomGuestsNumber
  },
  name: 'GuestInformation',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    roomType: Object,
    notChange: Boolean,
    facility: Object,
    change: Boolean,
    isFirstBooking: {
      type: Boolean,
      default: false
    },
    bookingTypeId: Number
  },
  data () {
    return {
      preCheckIn: false
    }
  },
  computed: {
    preCheckInLabel () {
      return this.preCheckIn ? this.$t('text.preCheckInInfoLater') : this.$t('text.enterYourPreCheckInInfo')
    },
    adults () {
      return this.value.filter(g => !g.child)
    },
    representative () {
      return this.adults.find(g => g.representative)
    },
    otherAdults () {
      return this.adults.filter(g => !g.representative)
    },
    children () {
      return this.value.filter(g => g.child && !g.bedShared)
    },
    bedShares () {
      return this.value.filter(g => g.child && g.bedShared)
    },
    adultsCount: {
      get () {
        return this.adults.length
      },
      set (newCount) {
        if (this.adultsCount > newCount) {
          // we must remove the adults from the last
          let counter = this.representative ? 1 : 0 // start from 1 because the representative must be kept
          const newValue = []
          this.value.forEach((guest, index) => {
            if (guest.child) newValue.push(guest)
            else if (guest.representative) {
              newValue.push(guest)
            } else if (newCount > counter) {
              newValue.push(guest)
              counter++
            }
          })
          this.emitInput(newValue)
        } else {
          this.emitInput(this.value.concat([...Array(newCount - this.adultsCount)].map(() => ({
            child: false,
            bedShared: false
          }))))
        }
      }
    },
    childrenCount: {
      get () {
        return this.children.length
      },
      set (newCount) {
        if (this.childrenCount > newCount) {
          // we must remove the children from the last
          let counter = 0
          const newValue = []
          this.value.forEach((guest, index) => {
            if (!guest.child) newValue.push(guest)
            else if (guest.bedShared) newValue.push(guest)
            else if (newCount > counter) {
              newValue.push(guest)
              counter++
            }
          })
          this.emitInput(newValue)
        } else {
          this.emitInput(this.value.concat([...Array(newCount - this.childrenCount)].map(() => ({
            child: true,
            bedShared: false
          }))))
        }
      }
    },
    bedShareCount: {
      get () {
        return this.bedShares.length
      },
      set (newCount) {
        if (this.bedShareCount > newCount) {
          // we must remove the bedShareCount from the last
          let counter = 0
          const newValue = []
          this.value.forEach((guest, index) => {
            if (!guest.child) newValue.push(guest)
            else if (!guest.bedShared) newValue.push(guest)
            else if (newCount > counter) {
              newValue.push(guest)
              counter++
            }
          })
          this.emitInput(newValue)
        } else {
          this.emitInput(this.value.concat([...Array(newCount - this.bedShareCount)].map(() => ({
            child: true,
            bedShared: true
          }))))
        }
      }
    },
    roomsGuestsAvailability () {
      return {
        maxOccupancy: this.roomType.maxOccupancy,
        bedShareMaxOccupancy: this.roomType.bedShareMaxOccupancy ?? 0
      }
    },
    numOfAdultsItems () {
      return numberArrayGenerator(this.roomsGuestsAvailability.maxOccupancy - this.childrenCount)
    },
    numOfChildrenItems () {
      return numberArrayGenerator(this.roomsGuestsAvailability.maxOccupancy - this.adultsCount, false)
    },
    numOfBedShareItems () {
      return numberArrayGenerator(this.roomsGuestsAvailability.bedShareMaxOccupancy, false)
    }
  },
  methods: {
    togglePreCheckIn () {
      this.preCheckIn = !this.preCheckIn
      this.$nextTick(() => {
        document.getElementById('anchor').scrollIntoView()
      })
    },
    getIndexOf (current, index) {
      return this.value
        .filter((g, ind) =>
          ind < index &&
          g.child === current.child &&
          g.bedShared === current.bedShared
        ).length + 1
    },
    changeGuest (index, newGuest) {
      const copied = this.value.slice()
      copied.splice(index, 1, newGuest)
      this.emitInput(copied)
    },
    emitInput (newValue) {
      // in some cases like in TO2020-868, there can be adult = 0 bookings.
      // in that case the first adult should be the new representativ
      const addRepresentative = !newValue.some(g => g.representative)
      if (addRepresentative) {
        // find the first adult and convert it to representative
        const found = newValue.find(g => !g.child)
        if (found) found.representative = true
      }

      this.$emit('input', newValue)
    },
    onGuestNumberChange () {
      this.$emit('guest-number-change', {
        adults: this.adultsCount,
        children: this.childrenCount,
        bedShares: this.bedShareCount
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.vertical-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  div {
    width: 100%;
  }
}
</style>
