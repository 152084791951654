<template>
  <v-text-field
    name="password"
    solo
    outlined
    flat
    required
    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
    :type="showPassword ? 'text' : 'password'"
    :value="value"
    @input="input"
    :rules="[$rules.required]"
    @click:append="showPassword = !showPassword"
    autocomplete="current-password"
    :hideDetails="hideDetails"
    :label="label"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showPassword: false
    }
  },
  methods: {
    input (value) {
      this.$emit('input', value)
    }
  }
}
</script>
