<template>
  <div>
    <v-form ref="form">
      <GuestInformation
        v-model="booking.guests"
        :roomType="booking.room.roomType"
        :isFirstBooking="true"
        :bookingTypeId="booking.bookingTypeId"
        change
        @guest-number-change="recalculate"
      />

      <div v-if="canUseTvp && additionalFee.jpy">
        <TVPPrePaymentChangeForm
          ref="prePaymentForm"
          :newAccommodationFee="onlyAccommodationJpyAfterUpdate"
          :onChange="changeTvpPrePayment"
          :onChangeText="$t('reservationAndUsageHistory.changeToTheAboveContent')"
          :default-value="0"
          :parentCanSave="canSave"
        />
      </div>

      <div class="px-3" v-else>
        <v-btn
          elevation="0"
          rounded
          block
          x-large
          @click="confirm"
          :disabled="!canSave"
          class="text-title mx-auto --white mb-3"
          color="bg-orange--grad"
        >
          {{ $t("reservationAndUsageHistory.changeToTheAboveContent") }}
        </v-btn>
      </div>
    </v-form>
    <ConfirmationOfAdditionalDays
      :reason="$t('text.dueToChangesInGuestInfo')"
      :visible="confirmModal"
      :additionalFee="additionalFee.jpy"
      :priceUnit="priceUnit"
      @close="confirmModal = false"
      @confirm="changeBookingGuests"
    />
  </div>
</template>

<script>
import { selectedBookingComputed } from '../../selected-booking.mixin'
import GuestInformation from '../../../../components/ReservationComponents/GuestInformation'
import ConfirmationOfAdditionalDays from '@/views/Booking/components/ConfirmationOfAdditionalDays.vue'
import TVPPrePaymentChangeForm from './TVPPrePaymentChange/TVPPrePaymentChangeForm.vue'

export default {
  name: 'GuestInformationChange',
  mixins: [selectedBookingComputed],
  components: {
    GuestInformation,
    ConfirmationOfAdditionalDays,
    TVPPrePaymentChangeForm
  },
  async mounted () {
    await this.recalculate()
  },
  methods: {
    back () {
      this.$router.push({
        name: 'home-booking-reservation-details-detail-reservation-change',
        params: { id: this.id.toString() }
      })
    },
    async confirm () {
      if (this.$refs.form.validate()) {
        await this.recalculate()
        this.confirmModal = true
      }
    },
    async changeBookingGuests ({ password }) {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('updateBookingGuests', {
            bookingId: this.id,
            guests: this.booking.guests,
            password,
            newPrePayment: this.newPrePayment
          })
        })
      })

      this.confirmModal = false
      this.completeChange()
    },
    async recalculate () {
      this.onlyAccommodationJpyAfterUpdate = this.currentAccommodationFee.onlyAccommodationFeeJpy
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          const result = await this.$store.dispatch('calculateUpdateBookingGuests', {
            booking: this.booking, guests: this.booking.guests
          })
          if (this.isPointBasedProduct) {
            this.totalPointAfterUpdate = result.totalPrice
            this.totalJpyAfterUpdate = result.totalFee
          } else {
            this.totalPointAfterUpdate = null
            this.totalJpyAfterUpdate = result.totalPrice + result.totalFee
            this.onlyAccommodationJpyAfterUpdate = result.totalPrice
          }
        })
      })
    },
    changeTvpPrePayment (newPrePayment) {
      this.newPrePayment = newPrePayment
      this.confirm()
    }
  },
  data () {
    return {
      confirmModal: false,
      totalPointAfterUpdate: null,
      totalJpyAfterUpdate: null,
      onlyAccommodationJpyAfterUpdate: null,
      newPrePayment: null
    }
  },
  computed: {
    canSave () {
      return this.booking.guests.every(g => !!g.name)
    },
    additionalFee () {
      console.log(this.totalJpyAfterUpdate, this.currentAccommodationFee)
      const result = {
        point: this.isPointBasedProduct ? (this.totalPointAfterUpdate - this.currentAccommodationFee.sp - this.currentAccommodationFee.fp) : 0,
        jpy: this.totalJpyAfterUpdate - this.currentAccommodationFee.jpy
      }
      return result
    }
  },
  watch: {
    additionalFee () {
      this.newPrePayment = null
    }
  }
}
</script>
