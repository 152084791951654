<template>
  <div class="text-left">
    <v-form ref="form">
      <v-row class="ma-0 w-100">
        <v-col :cols="newLineTitle ? 12 : 2" class="pa-0">
          <h4 for="numberOfAdults" class="text-title --w-bold" :class="titleClass">
            {{ $t("common.adult") }}
          </h4>
        </v-col>
        <v-col cols="3" class="pa-0 mt-2">
          <v-select
            id="numberOfAdults"
            :items="numOfAdultsItems"
            :value="numberOfAdults"
            @input="v => emitChange('onNumberOfAdultsChange', v)"
            :rules="rules.maxOccupancy"
            class="select-align-center"
            solo
            flat
            outlined
            hide-details
          />
        </v-col>
        <v-col
          cols="2"
          class="pa-0 text-left pl-2 text-title --w-medium" :class="labelClass"
          align-self="end"
        >
          {{ $t("common.firstName") }}
        </v-col>
      </v-row>
      <v-row class="ma-0 mt-3">
        <v-col :cols="newLineTitle ? 12 : 2" class="pa-0 pt-2">
          <h4 class="text-title --w-bold" :class="titleClass">
            {{ $t("common.child") }}
          </h4>
        </v-col>
        <v-col cols="5" class="pa-0 mt-2">
          <v-row class="ma-0">
            <v-col cols="12" class="pa-0 mb-2">
              <label for="numberOfBedShare" class="text-left text-title --w-medium" :class="labelClass">
                {{ $t("common.sleepTogether") }}
              </label>
            </v-col>
            <v-col cols="7" class="pa-0">
              <v-select
                id="numberOfBedShare"
                :disabled="disableNumOfBedShare"
                :items="numOfBedShareItems"
                :value="numberOfBedShare"
                @input="v => emitChange('onNumberOfBedShareChange', v)"
                class="select-align-center"
                solo
                flat
                outlined
                hide-details
              />
            </v-col>
            <v-col
              cols="3"
              class="pa-0 text-left pl-2 text-title --w-medium" :class="labelClass"
              align-self="end"
            >
              {{ $t("common.firstName") }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5" class="pa-0 mt-2">
          <v-row class="ma-0">
            <v-col cols="12" class="pa-0 mb-2">
              <label for="numberOfChildren" class="text-left text-title --w-medium" :class="labelClass">
                {{ $t("common.noSleepTogether") }}
              </label>
            </v-col>
            <v-col cols="7" class="pa-0">
              <v-select
                id="numberOfChildren"
                :items="numOfChildrenItems"
                :value="numberOfChildren"
                @input="v => emitChange('onNumberOfChildrenChange', v)"
                :rules="rules.maxOccupancy"
                class="select-align-center"
                solo
                flat
                outlined
                hide-details
              />
            </v-col>
            <v-col
              cols="3"
              class="pa-0 text-left pl-2 text-title --w-medium" :class="labelClass"
              align-self="end"
            >
              {{ $t("common.firstName") }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { numberArrayGenerator } from '@/utils/generator'

export default {
  name: 'RoomGuestsNumber',
  props: {
    maxOccupancy: Number,
    bedShareMaxOccupancy: Number,
    numberOfAdults: Number,
    numberOfChildren: Number,
    numberOfBedShare: Number,
    titleClass: String,
    labelClass: String,
    newLineTitle: Boolean
  },
  computed: {
    numOfAdultsItems () {
      return numberArrayGenerator(this.maxOccupancy)
    },
    numOfChildrenItems () {
      return numberArrayGenerator(this.maxOccupancy, false)
    },
    numOfBedShareItems () {
      return numberArrayGenerator(this.bedShareMaxOccupancy, false)
    },
    disableNumOfBedShare () {
      return this.bedShareMaxOccupancy <= 0
    },
    rules () {
      return {
        maxOccupancy: [
          _ => this.numberOfAdults + this.numberOfChildren <= this.maxOccupancy
        ]
      }
    }
  },
  watch: {
    disableNumOfBedShare: {
      immediate: true,
      handler (val) {
        if (val) {
          // if disabled, the value should be 0
          this.$emit('onNumberOfBedShareChange', 0)
          if (this.numberOfBedShare) {
            // increase the number of children for the decreased bedShared
            this.$emit('onNumberOfChildrenChange', this.numberOfChildren + this.numberOfBedShare)
          }
        }
      }
    }
  },
  methods: {
    emitChange (name, value) {
      this.$emit(name, value)
      // must run the validation because a change in one select may affect the validation result of others
      this.$nextTick(() => {
        this.$refs.form.validate()
      })
    }
  }
}
</script>
